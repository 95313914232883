<template>
  <v-container fluid>
    <ClientMap :mapData="stores" />
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import ClientMap from "../components/ClientMap.vue";
export default {
  components: { ClientMap },
  data() {
    return {
      errors: [],
      loading_status: false,
      stores: [],
    };
  },
  mounted() {
    this.get_stores();
  },
  methods: {
    get_clients() {
      this.loading_status = true;
      var qry = {
        account: this.$store.state.profile.account,
        table: "clients",
      };
      webserver("get_table", qry, (data) => {
        this.clients = data;
      });
    },
    get_stores() {
      this.items_bygroup = null;
      var qry = {
        account: this.$store.state.profile.account,
        table: "stores",
        filters: [
          {
            field: "location",
            operator: "<>",
            value: "",
          },
        ],
      };
      if (this.$store.state.profile.user_access === "Sales") {
        qry.filters.push({
          field: "agent",
          operator: "=",
          value: this.$store.state.profile.name,
        });
      }
      this.loading_status = true;
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        data.sort(function (a, b) {
          return parseFloat(a.name) - parseFloat(b.name);
        });
        this.stores = data;
      });
    },
  },
};
</script>


